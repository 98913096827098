import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface Participant {
  userId: string;
}

interface ParticipantListProps {
  participants: Participant[];
  onClose: () => void;
}

const ParticipantList: React.FC<ParticipantListProps> = ({ participants, onClose }) => {
  const getInitials = (name: string) => {
    return name.split(' ').map(n => n[0]).join('').toUpperCase();
  };

  return (
    <div className="participant-list">
      <div className="participant-list-header">
        <h3>Participants</h3>
        <button className="participant-list-close" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      {!participants || participants.length === 0 ? (
        <p>No participants</p>
      ) : (
        <ul>
          {participants.map((participant, index) => (
            <li key={index} className="participant-item">
              <div className="participant-initials">{getInitials(participant.userId)}</div>
              <span className="participant-name">{participant.userId}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ParticipantList;