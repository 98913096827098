import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import useStreamExistence from '../hooks/useStreamExistence';

interface JoinProps {
  setUserName: (name: string) => void;
}

const Join: React.FC<JoinProps> = ({ setUserName }) => {
  const [name, setName] = useState('');
  const { roomId: paramRoomId } = useParams<{ roomId?: string }>();
  const location = useLocation();
  const navigate = useNavigate();

  const [roomId, setRoomId] = useState('');
  const streamExists = useStreamExistence(roomId);

  useEffect(() => {
    const stateRoomId = location.state?.from?.pathname?.split('/').pop();
    const newRoomId = paramRoomId || stateRoomId || '';
    console.log('Setting roomId:', newRoomId);
    setRoomId(newRoomId);
  }, [paramRoomId, location.state]);

  useEffect(() => {
    console.log('Current streamExists value:', streamExists);
  }, [streamExists]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Form submitted. Name:', name, 'RoomId:', roomId, 'StreamExists:', streamExists);
    if (name && roomId && streamExists) {
      localStorage.setItem('userName', name);
      setUserName(name);
      navigate(`/room/${roomId}`);
    }
  };

  console.log('Rendering Join component. StreamExists:', streamExists);

  if (streamExists === false) {
    return (
      <div className="join-container">
        <h2>404: Stream Not Found</h2>
        <p>The requested room does not exist or is not currently active.</p>
      </div>
    );
  }

  return (
    <div className="join-container">
      {streamExists === null ? (
        <p>Checking stream availability...</p>
      ) : (
        <form onSubmit={handleSubmit} className="join-form">
          <input
            className="join-input"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your name"
            required
          />
          <button className="join-button" type="submit" disabled={!name.trim() || !streamExists}>
            Join
          </button>
        </form>
      )}
    </div>
  );
};

export default Join;