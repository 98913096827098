import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Video360 from './Video360';
import MuteButton from './MuteButton';
import ParticipantList from './ParticipantList';
import useWebRTC from '../hooks/useWebRTC';
import Footer from './Footer';

interface MainPageProps {
  userName: string;
}

const MainPage: React.FC<MainPageProps> = ({ userName }) => {
  const { roomId } = useParams<{ roomId: string }>();
  const [showParticipants, setShowParticipants] = useState(false);
  const { participants, isMuted, toggleMute, sendChatMessage } = useWebRTC(userName, roomId || '');

  useEffect(() => {
    console.log('Current participants:', participants);
  }, [participants]);

  if (!userName || !roomId) {
    return <div>Loading...</div>;
  }

  return (
    <div className="main-page">
      <div className="video-container">
        <div className="video-wrapper">
          <Video360 roomId={roomId || ''} />
        </div>
      </div>
      <Footer isMuted={isMuted} toggleMute={toggleMute} />
      <button
        className="participant-button"
        onClick={() => setShowParticipants(!showParticipants)}
      >
        <FontAwesomeIcon icon={faUser} />
      </button>
      {showParticipants && (
        <ParticipantList
          participants={participants}
          onClose={() => setShowParticipants(false)}
        />
      )}
    </div>
  );
};

export default MainPage;