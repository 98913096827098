import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Join from './components/Join';
import MainPage from './components/MainPage';
import ProtectedRoute from './components/ProtectedRoute';
import './styles.css';

const App: React.FC = () => {
  const [userName, setUserName] = useState<string>('');

  useEffect(() => {
    const storedUserName = localStorage.getItem('userName');
    if (storedUserName) {
      setUserName(storedUserName);
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/join" replace />} />
        <Route path="/join" element={<Join setUserName={setUserName} />} />
        <Route path="/join/:roomId" element={<Join setUserName={setUserName} />} />
        <Route 
          path="/room/:roomId" 
          element={
            <ProtectedRoute>
              <MainPage userName={userName} />
            </ProtectedRoute>
          } 
        />
      </Routes>
    </Router>
  );
};

export default App;