import React from 'react';
import { Navigate, useParams, useLocation } from 'react-router-dom';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { roomId } = useParams<{ roomId: string }>();
  const location = useLocation();
  const userName = localStorage.getItem('userName');

  if (!userName) {
    // Redirect to /join with the current roomId and return URL
    return <Navigate to={`/join/${roomId}`} state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;