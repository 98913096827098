import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons';

interface MuteButtonProps {
  isMuted: boolean;
  toggleMute: () => void;
}

const MuteButton: React.FC<MuteButtonProps> = ({ isMuted, toggleMute }) => {
  return (
    <button
      className={`mute-button ${isMuted ? 'muted' : ''}`}
      onClick={toggleMute}
    >
      <FontAwesomeIcon icon={isMuted ? faMicrophoneSlash : faMicrophone} />
    </button>
  );
};

export default MuteButton;