import { useState, useEffect, useRef, useCallback } from 'react';
import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor';

interface Participant {
  userId: string;
}

const useWebRTC = (userName: string, roomId: string) => {
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [isMuted, setIsMuted] = useState(false);
  const webRTCAdaptorRef = useRef<WebRTCAdaptor | null>(null);
  const websocketRef = useRef<WebSocket | null>(null);

  const initSignalingServer = useCallback(() => {
    console.log(`Initializing signaling server connection for user ${userName} in room ${roomId}`);
    websocketRef.current = new WebSocket(`wss://navstreammedia.mitikaz.com:3443?userId=${encodeURIComponent(userName)}&roomId=${encodeURIComponent(roomId)}`);

    websocketRef.current.onopen = () => {
      console.log(`WebSocket connection opened for user ${userName} in room ${roomId}`);
      // Send join message when connection is established
      websocketRef.current?.send(JSON.stringify({ type: 'join', roomId }));
    };

    websocketRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log(`Received message from signaling server for user ${userName}:`, data);
      switch (data.type) {
        case 'welcome':
          console.log(data.message);
          break;
        case 'participants':
        case 'participantList':
          console.log(`Updating participants for user ${userName}:`, data.participants);
          setParticipants(data.participants);
          break;
        case 'userJoined':
          console.log(`User ${data.userId} joined the room`);
          break;
        case 'userLeft':
          console.log(`User ${data.userId} left the room`);
          break;
        case 'messageSent':
          console.log(`Received chat message from ${data.senderId}:`, data.message);
          break;
        default:
          console.log(`Unknown message type: ${data.type}`);
      }
    };

    websocketRef.current.onerror = (error) => {
      console.error(`WebSocket error for user ${userName}:`, error);
    };

    websocketRef.current.onclose = () => {
      console.log(`WebSocket connection closed for user ${userName}`);
      // Attempt to reconnect after a short delay
      setTimeout(() => initSignalingServer(), 5000);
    };
  }, [userName, roomId]);

  useEffect(() => {
    initSignalingServer();

    return () => {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
    };
  }, [initSignalingServer]);

  const requestMediaPermissions = useCallback(async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
      console.log('Media permissions granted');
      return true;
    } catch (error) {
      console.error('Error requesting media permissions:', error);
      return false;
    }
  }, []);

  const initWebRTC = useCallback(async () => {
    console.log('Initializing WebRTC for 360 video');
    const permissionsGranted = await requestMediaPermissions();
    if (!permissionsGranted) {
      console.error('Unable to initialize WebRTC: Permissions not granted');
      return;
    }

    webRTCAdaptorRef.current = new WebRTCAdaptor({
      websocket_url: `wss://navstreammedia.mitikaz.com:5443/LiveApp/websocket`,
      mediaConstraints: { video: false, audio: true },
      peerconnection_config: { iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] },
      sdp_constraints: { OfferToReceiveAudio: true, OfferToReceiveVideo: true },
      callback: (info: string, obj: any) => {
        console.log('WebRTC callback:', info, obj);
        if (info === 'initialized') {
          console.log('WebRTC adaptor initialized');
          webRTCAdaptorRef.current?.play(roomId);
        }
      },
      callbackError: (error: string, message: string) => {
        console.error('WebRTC Error:', error, message);
      }
    });
  }, [roomId, requestMediaPermissions]);

  const toggleMute = () => {
    setIsMuted(!isMuted);
    // Implement mute/unmute logic for voice chat
  };

  const sendChatMessage = (message: string) => {
    if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
      websocketRef.current.send(JSON.stringify({ type: 'sendMessage', roomId, message }));
    }
  };

  return { participants, isMuted, toggleMute, sendChatMessage };
};

export default useWebRTC;