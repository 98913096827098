import { useState, useEffect } from 'react';

const useStreamExistence = (roomId: string) => {
  const [streamExists, setStreamExists] = useState<boolean | null>(null);

  useEffect(() => {
    console.log('useStreamExistence effect running for room:', roomId);
    if (!roomId) {
      console.log('No roomId provided, setting streamExists to null');
      setStreamExists(null);
      return;
    }

    const checkStreamExistence = async () => {
      console.log('Checking stream existence for room:', roomId);
      try {
        const url = `https://navstreammedia.mitikaz.com:5443/LiveApp/rest/v2/broadcasts/${roomId}`;
        console.log('Fetching from URL:', url);
        const response = await fetch(url);
        console.log('Response status:', response.status);
        if (response.ok) {
          const data = await response.json();
          console.log('Stream exists, data:', data);
          setStreamExists(true);
        } else if (response.status === 404) {
          console.log('Stream does not exist');
          setStreamExists(false);
        } else {
          console.error('Error checking stream existence:', response.statusText);
          setStreamExists(null);
        }
      } catch (error) {
        console.error('Error checking stream existence:', error);
        setStreamExists(null);
      }
    };

    checkStreamExistence();
  }, [roomId]);

  return streamExists;
};

export default useStreamExistence;