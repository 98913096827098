import React, { useEffect, useRef } from 'react';
import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor';

interface Video360Props {
  roomId: string;
}

const Video360: React.FC<Video360Props> = ({ roomId }) => {
  const sceneRef = useRef<HTMLASceneElement>(null);
  const webRTCAdaptorRef = useRef<WebRTCAdaptor | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    console.log('Video360 component mounted for room:', roomId);
    
    if (!videoRef.current) {
      videoRef.current = document.createElement('video');
      videoRef.current.id = 'localVideo';
      videoRef.current.autoplay = true;
      videoRef.current.muted = true;
      videoRef.current.playsInline = true;
      videoRef.current.crossOrigin = 'anonymous';
      videoRef.current.style.display = 'none';
      document.body.appendChild(videoRef.current);
      console.log('Video element created:', videoRef.current);
    }

    const initWebRTC = () => {
      webRTCAdaptorRef.current = new WebRTCAdaptor({
        websocket_url: `wss://navstreammedia.mitikaz.com:5443/LiveApp/websocket`,
        mediaConstraints: {
          video: false,
          audio: false
        },
        peerconnection_config: {
          'iceServers': [{'urls': 'stun:stun1.l.google.com:19302'}]
        },
        sdp_constraints: {
          OfferToReceiveAudio : true,
          OfferToReceiveVideo : true,
        },
        callback: (info: string, obj: any) => {
          console.log("WebRTC callback:", info, obj);
          if (info === "initialized") {
            console.log("WebRTC adaptor initialized, playing stream:", roomId);
            webRTCAdaptorRef.current?.play(roomId);
          } else if (info === "play_started") {
            console.log("Play started for stream:", obj.streamId);
          } else if (info === "newStreamAvailable") {
            console.log("New stream available");
            if (videoRef.current) {
              videoRef.current.srcObject = obj.stream;
              console.log('Video srcObject set:', videoRef.current.srcObject);
              videoRef.current.play().then(() => {
                console.log('Video started playing');
              }).catch(error => {
                console.error('Error playing video:', error);
              });
            }

            if (sceneRef.current) {
              let aVideoSphere = sceneRef.current.querySelector('a-videosphere');
              if (!aVideoSphere) {
                aVideoSphere = document.createElement('a-videosphere');
                aVideoSphere.setAttribute('src', '#localVideo');
                aVideoSphere.setAttribute('rotation', '0 -90 0');
                sceneRef.current.appendChild(aVideoSphere);
                console.log('A-Frame videosphere created:', aVideoSphere);
              }
            }
          }
        },
        callbackError: (error: any, message: string) => {
          console.error("WebRTC adaptor error:", error, message);
        }
      });
    };

    const setupAFrameScene = () => {
      if (sceneRef.current) {
        let aVideoSphere = sceneRef.current.querySelector('a-videosphere');
        if (!aVideoSphere) {
          aVideoSphere = document.createElement('a-videosphere');
          aVideoSphere.setAttribute('src', '#localVideo');
          aVideoSphere.setAttribute('rotation', '0 -90 0');
          sceneRef.current.appendChild(aVideoSphere);
          console.log('A-Frame videosphere created:', aVideoSphere);
        }
      }
    };

    if (sceneRef.current) {
      if (sceneRef.current.hasLoaded) {
        setupAFrameScene();
      } else {
        sceneRef.current.addEventListener('loaded', setupAFrameScene);
      }
    }

    initWebRTC();
    
    return () => {
      console.log('Video360 component unmounting for room:', roomId);
      if (webRTCAdaptorRef.current) {
        webRTCAdaptorRef.current.stop(roomId);
        webRTCAdaptorRef.current.closeWebSocket();
      }
      if (videoRef.current) {
        videoRef.current.remove();
        videoRef.current = null;
      }
      if (sceneRef.current) {
        sceneRef.current.removeEventListener('loaded', setupAFrameScene);
      }
    };
  }, [roomId]);

  return (
    <a-scene embedded ref={sceneRef}>
      <a-camera></a-camera>
      {/* ... other A-Frame elements ... */}
    </a-scene>
  );
};

export default Video360;