import React from 'react';
import MuteButton from './MuteButton';

interface FooterProps {
  isMuted: boolean;
  toggleMute: () => void;
}

const Footer: React.FC<FooterProps> = ({ isMuted, toggleMute }) => {
  return (
    <footer className="main-footer">
      <MuteButton isMuted={isMuted} toggleMute={toggleMute} />
    </footer>
  );
};

export default Footer;